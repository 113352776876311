import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Assinatura from '../../components/Assinatura';
import CardJornal from '../../components/CardJornal';
import Axios from '../../utils/Axios';

const Links = () => {
  const [dados, setDados] = useState([]);

  useEffect( () => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
    Axios.get(`/jornais`).then((response) => {
    setDados(response.data)
    })
  }, []);

  return (
    <>
      <Header min="true" name="Jornais"/>
      <Container style={{ maxWidth: "80%"}}>
        <Row style={{ marginTop: "100px" }}>
          {dados !== undefined && dados.map((item, index) => (
            <Col md={4} key={index}>
              <CardJornal imagem={item.capa} pdf={item.pdf} titulo={item.titulo} mes={item.mes} ano={item.ano} />
            </Col>
          ))}
        </Row>
      </Container>  
      <Footer />
      <Assinatura />
    </>
  );
};

export default Links;